<template>
  <b-sidebar id="guides-info-sidebar" no-header right shadow backdrop>
    <template #default="{ hide }">
      <div class="container-wrapper">
        <section class="header">
          <div class="guides-info-title">Informações do lote</div>
          <div class="actions">
            <Close class="close" @click="hide" />
          </div>
        </section>

        <section class="guides-summary" v-if="summary">
          <div class="guides-info-title">{{ healthplan }}</div>
          <dl>
            <div class="guides-summary-row">
              <dt>Envio:</dt>
              <dd v-if="summary?.updated_at">
                {{ moment(summary?.updated_at).format('DD/MM/YYYY') }}
              </dd>
              <dt>Tipo:</dt>
              <dd v-if="summary?.type">
                {{ getTypes(summary?.type) }}
              </dd>
            </div>
            <div class="guides-summary-row">
              <dt>Versão TISS:</dt>
              <dd>{{ summary?.xml_version }}</dd>
            </div>
            <div class="guides-summary-row">
              <dt>Lote Nº:</dt>
              <dd>{{ summary?.number }}</dd>
              <dt>Fatura Nº:</dt>
              <dd>{{ summary?.tiss_invoice?.number }}</dd>
            </div>
          </dl>
        </section>

        <hr />

        <section class="guides-summary-items">
          <b-form-group>
            <label for="protocol"
              >Nº protocolo <span class="optional">(opcional)</span></label
            >
            <b-form-input
              v-model="form.protocol_number"
              id="protocol"
              type="number"
              placeholder="Descrever"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="protocol"
              >Nota Fiscal <span class="optional">(opcional)</span></label
            >
            <b-form-input
              v-model="form.nf"
              id="invoice"
              type="number"
              placeholder="Descrever"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="payment_date"
              >Previsão de pagamento
              <span class="optional">(opcional)</span></label
            >
            <date-picker
              v-model="form.payment_date"
              id="payment_date"
              format="DD/MM/YYYY"
              placeholder="DD/MM/AAAA"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              autocomplete="off"
            />
          </b-form-group>

          <b-form-group>
            <label for="account_analysis"
              >Análise da conta <span class="optional">(opcional)</span></label
            >
            <b-form-input
              v-model="form.account_analysis"
              id="invoice"
              type="number"
              placeholder="Descrever"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="payment_statement"
              >Demonstrativo de pagamento
              <span class="optional">(opcional)</span></label
            >
            <b-form-input
              v-model="form.payment_statement"
              id="invoice"
              type="number"
              placeholder="Descrever"
              required
            ></b-form-input>
          </b-form-group>
        </section>

        <div class="wrapper-button">
          <b-button block variant="primary" @click="saveChanges">
            Atualizar lote
          </b-button>

          <b-button variant="outline-primary" block @click="hide">
            Ocultar informações do lote
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import api from '@/modules/tiss/manageGuides/api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { getTypes } from '@/utils/invoicingTissHelper'

export default {
  name: 'GuidesInfoSidebar',
  components: {
    Close: () => import('@/assets/icons/close.svg')
  },
  props: {
    batchId: String,
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      healthplan: null,
      summary: {},
      form: {
        protocol_number: null,
        nf: null,
        payment_date: null,
        account_analysis: null,
        payment_statement: null
      },
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      }
    }
  },
  methods: {
    getTypes,
    async openInfoSidebar() {
      const { data: response } = await api.getBatchResume(this.batchId, null, [])

      this.summary = response
      this.form = Object.assign({}, this.form, this.summary)
    },
    async saveChanges() {
      const isLoading = this.$loading.show()

      try {
        await api.updateTissBatchInfo(this.batchId, this.form)
        this.$toast.success('Informações do lote atualizadas com sucesso!')
        this.$root.$emit('bv::toggle::collapse', 'guides-info-sidebar')
        this.$emit('updateGuide')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    }
  },
  watch: {
    batchId: {
      handler() {
        this.openInfoSidebar()
      }
    }
  }
}
</script>

<style lang="scss">
#guides-info-sidebar {
  min-width: 450px;

  .title {
    display: flex;
    flex-direction: row;

    h4 {
      color: var(--dark-blue);
      font-weight: 700;
      font-size: 18px;
      margin-right: 20px;
    }

    h5 {
      color: var(--blue-600);
      font-weight: 600;
      font-size: 18px;

      span {
        color: var(--neutral-500);
      }
    }
  }

  .guides-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
    padding: 20px;
    border-radius: 8px;
    border: 1px dashed var(--neutral-300);
    background: var(--neutral-100);

    .guides-summary-row {
      display: grid;
      grid-template-columns: auto 1fr auto 3fr;
      gap: 10px;
    }

    dt {
      color: var(--neutral-700);
    }

    dd {
      margin: 0;
    }
  }

  .guides-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .guide-container {
      margin: 10px 0;
    }
  }

  .container-wrapper {
    padding: 0 1rem;
  }

  .header {
    display: flex;
    width: 100%;
    padding: 1rem 0;
    justify-content: space-between;
    align-items: center;
  }

  .guides-info-title {
    font-family: 'Red Hat Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    color: var(--dark-blue);
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .optional {
    font-size: 14px;
    color: var(--type-placeholder);
  }

  .close {
    width: 25px;
    fill: var(--neutral-500);
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    flex-direction: column;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    .btn-primary {
      color: var(--neutral-000);
    }

    .btn-outline-primary {
      margin-top: 10px;
      color: var(--blue-500);
    }

    .dropdown-item {
      font-weight: 400;
    }
  }
}
</style>